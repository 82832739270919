import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

export function serviceDirectorySearch(searchWord) {
  return http.get(apiUrl + 'serviceDirectory/service', {
    params: {
      businessName_contains: searchWord,
      serviceType_name_contains: searchWord,
    },

    //'english_multilang','service_24_7','accept_credit_card','special_equipment','deferred_payment','loyalty_program'
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

/* {
    rejectUnauthorized: false, // Disable certificate verification
  } */
export function bannerGallery() {
  //http://138.68.68.46/items/community_banner/1
  return axios.get(`${apiUrl}common/communityBanner`);
}

export function getbusinessTypes() {
  return http.get(`${apiUrl}serviceDirectory/businessTypes`);
}

export function serviceDirectoryVetSearch(searchWord) {
  return http.get(`${apiUrl}serviceDirectory/vet`, {
    params: {
      practiceName_contain: searchWord,
      practiceType_name_contains: searchWord,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function serviceDirectorySuggestedServiceSearch(searchWord) {
  return http.get(`${apiUrl}serviceDirectory/suggestedService`, {
    params: {
      practiceName_contain: searchWord,
      practiceType_name_contains: searchWord,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function suggestServiceProvider(data, userType) {
  const req = {
    streetAddress: data.streetAddress,
    country: data.country,
    state: data.state,
    city: data.city,
    postalCode: data.postalCode,
    emailAddress: data.emailAddress,
    phoneNo: data.phoneNo,
    website: data.website,
    english_multilang: data.english_multilang ? 1 : 0,
    service_24_7: data.service_24_7 ? 1 : 0,
    accept_credit_card: data.accept_credit_card ? 1 : 0,
    special_equipment: data.special_equipment ? 1 : 0,
    deferred_payment: data.deferred_payment ? 1 : 0,
    loyalty_program: data.loyalty_program ? 1 : 0,
    userEmailAddress: data.userEmailAddress,
    VerificationStatus: 0,
  };
  if (userType == 4) {
    req.serviceType = data.serviceType;
    req.businessName = data.businessName;
  } else {
    req.practiceType = data.serviceType;
    req.practiceName = data.businessName;
  }

  console.log(req);

  return axios.post(apiUrl + 'serviceDirectory/suggestServiceProvider/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });

  /*   {
    "streetAddress": "hhhh",
        "country" : 1,
        "state" :1,
        "city" : "geyt",
        "postalCode" : 16031,
        "emailAddress" :"aaaaa@gmail.com",
        "phoneNo": "+21366666668",
        "website" : "fffffff",
        "serviceType":1,
        "businessName":"gerhththyt",
        
        "english_multilang" :true,
        "service_24_7":true,
        "accept_credit_card" :true,
        "special_equipment" :true,
        "deferred_payment":true,
        "loyalty_program":true,
        "userEmailAddress":"kemoum.amira@gmail.com"
      } */
}

// getSDVet getSDSuggestedService getSDServiceProvider
export function getSDVet(id) {
  return http.get(apiUrl + 'serviceDirectory/vet/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function getSDSuggestedService(id) {
  return http.get(apiUrl + 'serviceDirectory/suggestedService/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function getSDServiceProvider(id) {
  return http.get(apiUrl + 'serviceDirectory/service/' + id);
}
